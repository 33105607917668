@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.s-grow-form {
    position: relative;

    &::before {
        content: '';

        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0px;
        left: 0;
        z-index: -1;
        transform: skewY(-7deg);

        background-color: $color-laguna;
    }
    
    .gform_wrapper.gravity-theme .gfield input.medium, .gform_wrapper.gravity-theme .gfield select.medium {
        width: 100%;
    }
}
.s-grow-form__container {
    padding-top: 150px;
    padding-bottom: 160px;

    position: relative;
    overflow: hidden;

    @media (min-width: $medium-size) {
        display: flex;
        flex-direction: column;

        padding-top: 200px;
        padding-bottom: 200px;
    }
}
.s-grow-form__head-text {
    a {
        text-decoration: underline;
        
        transition: 300ms ease;

        &:hover {
            @media (hover: hover) {
                opacity: .6;
            }
        }
    }
}
.s-grow-form__wr-bg {
    position: absolute;
    z-index: -1;

    &_top-right {
        width: 400px;
        height: 300px;

        top: 30px;
        right: 0;
        transform: translateX(160px);

        opacity: .1;

        @media (min-width: $large-size) {
            width: 870px;
            height: 650px;

            transform: translateX(540px);
        }
    }
    &_top-left {
        width: 300px;
        height: 300px;

        top: 90px;
        left: 0;
        transform: translateX(-12px);

        @media (min-width: $large-size) {
            width: 600px;
            height: 600px;

            transform: translateX(0px);
        }
    }
    &_bottom-right {
        width: 400px;
        height: 300px;

        right: 0;
        bottom: 60px;
        transform: translateX(35px);

        opacity: .1;

        @media (min-width: $large-size) {
            width: 870px;
            height: 650px;

            transform: translateX(55px);
        }
    }
}

.s-grow-form__head {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 30px;

    color: #ffffff;
    text-align: center;

    @media (min-width: $medium-size) {
        margin-bottom: 40px;
    }
}
.s-grow-form__heading {
    margin-bottom: 20px;
    max-width: 865px;

    color: #ffffff;
}

.s-grow-form__wr-form {
    padding: 50px 20px;

    background-color: #ffffff;
    border-radius: 20px;
    
    @media (min-width: $smallest-size) {
        padding: 50px 40px;
    }
    @media (min-width: $small-mid-size) {
        padding: 50px 20px;
    }
    @media (min-width: $small-size) {
        padding: 50px 40px;
    }
    @media (min-width: $medium-size) {
        padding: 50px 80px;
        width: 865px;
        align-self: center;
    }
}
.bl-grow-form {
    display: flex;
    flex-direction: column;

    @media (min-width: $small-mid-size) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }
}
.bl-grow-form__input-cell {
    margin-bottom: 30px;

    &_area {
        @media (min-width: $small-mid-size) {
            grid-column: 1 / 2 span;
        }
    }

    @media (min-width: $small-mid-size) {
        margin-bottom: 0;
    }
}
.bl-grow-form__btn {
    display: inline-flex;
    align-items: center;
    align-self: start;

    margin-top: 10px;

    @media (min-width: $small-mid-size) {
        justify-self: start;
        grid-column: 1 / 2 span;
    }

    span {
        margin-right: 12px;
    }
}

.s-grow-form .hubspot-form {
    form {
        display: grid;
        grid-row-gap: 30px;
    }
    fieldset {
        display: grid;
        grid-row-gap: 30px;
        grid-column-gap: 30px;

        max-width: none!important;

        & > * {
            position: relative;

            &:nth-child(2n) {
                @media (min-width: $small-mid-size) {
                    grid-column: 2;
                }
            }

            & > ul {
                margin-top: 0;
                margin-bottom: 0;
                padding-left: 20px;

                position: absolute;
                top: 100%;
                left: 0;

                & > li::marker {
                    color: $color-laguna;
                }
                
                label {
                    font-size: 12px;
                    line-height: 100%;
                }
            }
        }
    }
    .hs-form-field {
        width: 100%!important;
    }
    label {
        margin-bottom: 4px;

        font: {
            family: $font-family-solid;
            size: 14px;
            weight: 500;
        }
        line-height: 140%;
        color: $color-laguna;
    }
    .input {
        margin-right: 0!important;
    }
    input {
        &:not([type='submit']) {
            width: 100%!important;
            border: none;
            border-bottom: 1px solid $color-laguna;
            padding: 8px 0 12px!important;
            margin-right: 0!important;

            color: #222222;

            &:focus {
                outline: none;
            }
            &::placeholder {
                color: #adadad;
            }
        }
        &[type='submit'] {
            display: inline-block;

            margin-top: 10px;
            padding: 15px 25px;
            border: 1px solid transparent;
            border-color: $color-orange-dark;

            font-family: $font-family-solid;
            font-size: 14px;
            line-height: 130%;
            font-weight: 600;
            text-decoration: none;
            text-align: center;
            color: #ffffff;

            border-radius: 50px;
            transition: 300ms ease-in-out;
            background-color: $color-orange-dark;

            @media (hover: hover) {
                &:hover {
                    background-color: transparent;
                    color: $color-orange-dark;
                }
            }
        }
    }
    textarea {
        width: 100%!important;
        height: 90px;
        border: none;
        border-bottom: 1px solid $color-laguna!important;
        padding: 8px 0 12px!important;
        margin-right: 0!important;

        color: #222222;

        resize: none;

        &:focus {
            outline: none;
        }
        &::placeholder {
            color: #adadad;
        }
    }
}

.s-grow-form .gravity-form {
    .gform_fields {
        grid-row-gap: 30px!important;

        @media (min-width: $small-mid-size) {
            grid-column-gap: 32px!important;
        }
    }
    .gform_validation_errors {
        background: $color-laguna-light;
        border-radius: 20px;
        box-shadow: 0 1px 6px rgb(200 0 0 / 20%)!important;
        border-color: rgba(200, 0, 0, .15)!important;
    }
    h2.gform_submission_error,
    .gform-icon {
        color: $color-laguna-dark;
    }

    .gfield {
        .gfield_label {
            margin-bottom: 0;
        }
        .gfield_label,
        .gfield_label .gfield_required {
            font-family: Montserrat, sans-serif;
            font-size: 14px;
            line-height: 140%;
            font-weight: 500;
            color: $color-laguna;
        }
    }
    .ginput_container_select {
        position: relative;
        
        &::after {
            content: '';

            width: 8px;
            height: 8px;
            border-right: 1px solid $color-grey-light;
            border-bottom: 1px solid $color-grey-light;

            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(-70%, -50%) rotate(45deg);

        }
    }
    .ginput_container {
        select,
        input:not([type='submit']) {
            width: 100%;
            padding: 8px 0 10px;
            border: none;
            border-bottom: 1px solid $color-laguna;
            
            font-size: 16px!important;
            line-height: 160%!important;
            color: #222222;

            background-color: transparent;

            &:focus {
                outline: none;
            }
            &::placeholder {
                color: $color-grey-light;
            }
        }
        select {
            color: $color-grey-light;

            &.filled {
                color: #222222;
            }
            
            option {
                &:hover {
                    @media (hover: hover) {
                        background-color: $color-laguna-light;
                    }
                }
            }
            &:required:invalid {
                color: $color-grey-light;
            }
            option[value=""] {
                display: none;
            }
            option {
                color: #222222;
            }
        }
    }
    .gfield_validation_message,
    .validation_message {
        background: $color-laguna-light;
        color: $color-laguna-dark;
        box-shadow: 0 1px 6px rgb(200 0 0 / 30%)!important;
        border-color: rgba(200, 0, 0, .15)!important;

        border-radius: 20px!important;
    }
    input[type='submit'] {
        display: inline-block;

        margin-bottom: 0!important;
        margin-top: 10px;
        padding: 15px 25px;
        border: 1px solid $color-orange-dark;
        
        font-family: $font-family-solid;
        font-size: 14px;
        line-height: 130%!important;
        font-weight: 600;
        text-decoration: none;
        text-align: center;
        
        background-color: $color-orange-dark;
        border-radius: 50px;
        transition: 300ms ease-in-out;
        cursor: pointer;
        
        white-space: normal;
        color: #ffffff;

        &:hover {
            @media (hover: hover) {
                background-color: transparent;
                color: $color-orange-dark;
            }
        }
    }
    textarea {
        width: 100%!important;
        height: 90px!important;
        border: none;
        border-bottom: 1px solid $color-laguna!important;
        padding: 8px 0 12px!important;
        margin-right: 0!important;

        color: #222222;

        resize: none;

        &:focus {
            outline: none;
        }
        &::placeholder {
            color: #adadad;
        }
    }
    input[type='submit'] + img {
        margin-left: 12px;
    }
}